@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Base Styles */
html {
  scroll-behavior: smooth;
  height: 100%;
}

body {
  @apply bg-black text-white;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

/* Scroll Behavior */
@supports (-webkit-overflow-scrolling: touch) {
  body {
    -webkit-overflow-scrolling: touch;
  }
}

/* Section Styles */
.snap-section {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

/* Parallax Text */
.parallax {
  overflow: hidden;
  letter-spacing: -2px;
  line-height: 0.8;
  margin: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
}

.parallax .scroller {
  font-weight: 800;
  text-transform: uppercase;
  display: flex;
  white-space: nowrap;
  flex-wrap: nowrap;
  width: max-content;
}

.parallax span {
  display: block;
  margin-right: 30px;
}

/* Noise Effect */
.noise {
  position: fixed;
  inset: 0;
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 200 200' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noise'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.65' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%' height='100%' filter='url(%23noise)'/%3E%3C/svg%3E");
  opacity: 0.02;
  mix-blend-mode: overlay;
  pointer-events: none;
  z-index: 50;
}

/* Animations */
@keyframes fade-up {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-up {
  animation: fade-up 0.8s ease-out forwards;
}

@keyframes scroll-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes scroll-left2 {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.animate-scroll-left {
  animation: scroll-left 50s linear infinite;
}

.animate-scroll-left2 {
  animation: scroll-left2 50s linear infinite;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .parallax {
    letter-spacing: -1px;
  }

  .parallax .scroller {
    font-size: 32px;
  }
}

/* Smooth Transitions */
.transition-all {
  transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}

/* Custom Scrollbar */
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1);
}

::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.3);
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.4);
}
